import lottie from 'lottie-web'
import gsap from 'gsap'

const Component = {
  init() {
    this.approachAnimation()
  },
  approachAnimation() {
    const approachWrapper = $('.js-approach-wrapper')

    if (approachWrapper.length) {
      approachWrapper.each(function (index, el) {
        // const $wrapper = $(el)
        const $approachLottie = $('.js-approach-lottie', el)

        if ($approachLottie.length) {
          // const $approaches = $('.js-approach', el)
          let anURL = $approachLottie.data('file-url')

          const animation = lottie.loadAnimation({
            container: $approachLottie[0],
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: anURL,
          })

          animation.setSpeed(1)

          animation.addEventListener('data_ready', function () {
            gsap.timeline({
              scrollTrigger: {
                // once: true,
                start: 'top bottom-=200',
                end: 'bottom top+=100',
                trigger: $approachLottie[0],
                onToggle: () => animation.play(),
                onLeave: () => animation.goToAndStop(0),
                onLeaveBack: () => animation.goToAndStop(0),
              },
            })
          })
        }

        //
        // const animApproaches = () => {
        //   const totalFrames = animation.totalFrames
        //   const wrapperHeight = $wrapper.outerHeight()
        //   const headerHeight = $('.header').outerHeight() + 10
        //   const windowHeight = $(window).outerHeight()
        //
        //   killScrollTrigger(timeline)
        //
        //   if (windowHeight > wrapperHeight + headerHeight) {
        //     const scrollTriggerArgs = {
        //       trigger: $wrapper[0],
        //       start: 'top top+=' + headerHeight,
        //       end: '+=' + (headerHeight + wrapperHeight * $approaches.length),
        //       scrub: 3,
        //       pin: true,
        //       onUpdate: ({ progress }) => {
        //         // console.log('progress', progress) //eslint-disable-line
        //         animation.goToAndStop(totalFrames * progress, true)
        //
        //         // if (progress === 1) {
        //         //   animation.goToAndStop(totalFrames, true)
        //         // }
        //       },
        //     }
        //
        //     timeline = gsap.timeline({
        //       scrollTrigger: scrollTriggerArgs,
        //     })
        //
        //     $approaches.each(function (index, item) {
        //       timeline.fromTo(
        //         item,
        //         {
        //           opacity: index === 0 ? 0.1 : 0,
        //         },
        //         {
        //           opacity: 1,
        //           duration: 1,
        //           onStart: () => {
        //             // const currentFrame =
        //             //   (totalFrames / $approaches.length) * index
        //             // const nextFrame =
        //             //   (totalFrames / $approaches.length) * (index + 1)
        //             // animation.playSegments(
        //             //   [Math.round(currentFrame), Math.round(nextFrame)],
        //             //   true
        //             // )
        //           },
        //           onReverseComplete: () => {
        //             // const nextFrame = (totalFrames / $approaches.length) * index
        //             // const currentFrame =
        //             //   (totalFrames / $approaches.length) * (index + 1)
        //             // animation.playSegments(
        //             //   [Math.round(currentFrame), Math.round(nextFrame)],
        //             //   true
        //             // )
        //           },
        //         }
        //       )
        //     })
        //
        //     refreshScrollTrigger(ScrollTrigger)
        //   } else if (windowHeight <= wrapperHeight + headerHeight) {
        //     timeline = gsap.timeline({
        //       scrollTrigger: {
        //         // once: true,
        //         start: 'top bottom-=200',
        //         end: 'bottom top+=100',
        //         trigger: $approachLottie[0],
        //         onToggle: () => animation.play(),
        //       },
        //     })
        //
        //     refreshScrollTrigger(ScrollTrigger)
        //   }
        // }
        //
        // $(window).on('resize', function () {
        //   debounce(animApproaches, 1500)
        // })
      })
    }
  },
}

$(() => {
  Component.init()
})
